const Cookies = require('js-cookie');

// -----------------------------------------------------------------------------------------
	//Google Map
//-----------------------------------------------------------------------------------------

(function() {

    basedOnBrowser();
    const mapsScript = document.createElement('script');
    mapsScript.async = true;
    mapsScript.src = window.mapsUrl ;
    document.querySelector('head').appendChild(mapsScript);

}());

function basedOnBrowser() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        // Switching to v=3.36 solves this for now, but 3.36 will be removed next update, where it will default to the "v=quarterly" response
        window.mapsUrl = '//maps.googleapis.com/maps/api/js?callback=initMap&libraries=places&key=AIzaSyCccNc8Jd48EnDBh8G5o56720rdgAJulNc&v=3.36';
    } else {
        window.mapsUrl = '//maps.googleapis.com/maps/api/js?callback=initMap&libraries=places&key=AIzaSyCccNc8Jd48EnDBh8G5o56720rdgAJulNc';
    }
    return false;
}

function initMap() {

    if (document.getElementById('home-map')) {

        const center = new google.maps.LatLng(-36.8704433,174.752232);
        const mapOptions = {
            zoom: 17,
            maxZoom: 18,
            center: center,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            scrollwheel: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoomControl: false,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_BOTTOM
            },
            gestureHandling: 'cooperative',
            styles: [
                      {
                        "elementType": "geometry",
                        "stylers": [
                          {
                            "color": "#fdcf26"
                          }
                        ]
                      },
                      {
                        "elementType": "labels",
                        "stylers": [
                          {
                            "color": "#000000"
                          },
                          {
                            "visibility": "on"
                          }
                        ]
                      },
                      {
                        "elementType": "labels.icon",
                        "stylers": [
                          {
                            "visibility": "off"
                          }
                        ]
                      },
                      {
                        "elementType": "labels.text.stroke",
                        "stylers": [
                          {
                            "visibility": "off"
                          }
                        ]
                      },
                      {
                        "featureType": "road",
                        "stylers": [
                          {
                            "color": "#eabf23"
                          }
                        ]
                      },
                      {
                        "featureType": "road",
                        "elementType": "labels",
                        "stylers": [
                          {
                            "visibility": "on"
                          }
                        ]
                      },
                      {
                        "featureType": "road",
                        "elementType": "labels.text.fill",
                        "stylers": [
                          {
                            "color": "#000000"
                          }
                        ]
                      },
                      {
                        "featureType": "water",
                        "stylers": [
                          {
                            "color": "#fdcf1d"
                          }
                        ]
                      }
                    ]
        };

        const map = new google.maps.Map(document.getElementById('home-map'), mapOptions);

        //Pub
        var markerPosition = {
            lat: -36.870519, lng: 174.752195
        };
        var mapMarker = new google.maps.Marker({
            position: markerPosition, map: map,
            icon: {
                url: '/images/map-marker@2x.png',
                scaledSize: new google.maps.Size(120, 130)
            }
        })
        //Parking
        var markerPosition = {
            lat: -36.871529, lng: 174.751377
        };
        var mapMarker = new google.maps.Marker({
            position: markerPosition, map: map,
            icon: {
                url: '/images/map-marker-parking@2x.png?v=20200910',
                scaledSize: new google.maps.Size(85, 40.5)
            }
        })
    }
}
window.initMap = initMap;

// Draft javascript (martin)


$(".site-header__menu-toggle--mobile").click(function(){
    $(".js-body").toggleClass("menu-is-open");
});

$(function() {
  var wSize = $(window).width();
  $(window).resize(function() {
    if(wSize >= 849) {
      $('.js-body')
      .removeClass('menu-is-open')
    } else {

    }
  });

    $("#js-age-check").submit(function() {
        Cookies.set('over18', 'yes');
        $(".js-age-check-popup").hide();
    });
});
